/*@tailwind base;*/
@tailwind components;
@tailwind utilities;

/* disable tinymce warning */
.tox-notifications-container {
  display: none !important;
}
/* hide arrows for input with type === 'number' inside a parent with class === 'hide-arrows'  */
.hide-arrows::-webkit-inner-spin-button input,
.hide-arrows::-webkit-outer-spin-button input{
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.hide-arrows input {
  -moz-appearance: textfield !important;
}
.scrollbars::-webkit-scrollbar {
  display: none;
}
.scrollbars{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.simplebar-wrapper .dfl-sidebar-menu .MuiList-root{
  margin-top: 0;
}

.leaflet-control-attribution > a > svg {
  display: none !important;
}

.aspect-image {
  aspect-ratio: 7 / 5;
}

@supports not (aspect-ratio: 7 / 5) {
  .aspect-image::before {
    float: left;
    padding-top: 71.43%;
    content: '';
  }

  .aspect-image::after {
    display: block;
    content: '';
    clear: both;
  }
}

.aspect-banner-responsive {
  aspect-ratio: 1.38;
}

@supports not (aspect-ratio: 1.38) {
  .aspect-banner-responsive::before {
    float: left;
    padding-top: 72.62%;
    content: '';
  }

  .aspect-banner-responsive::after {
    display: block;
    content: '';
    clear: both;
  }
}

.aspect-banner-desktop {
  aspect-ratio: 3.17;
}

@supports not (aspect-ratio: 3.17) {
  .aspect-banner-desktop::before {
    float: left;
    padding-top: 31.6%;
    content: '';
  }

  .aspect-banner-desktop::after {
    display: block;
    content: '';
    clear: both;
  }
}

.optimized-star-filled-clip {
  clip-path: url(#star-icon-filled-clip) !important;
}

.optimized-star-empty-clip {
  clip-path: url(#star-icon-empty-clip) !important;
}

.optimized-large-star-clip {
  clip-path: url(#large-star-icon-filled-clip) !important;
}
